import { v4 as uuidv4 } from 'uuid';
import bg1 from '../../assets/img/custom/hover_image.jpg';
import slide1 from '../../assets/img/custom/railing/railing_1.jpg';
import slide2 from '../../assets/img/custom/railing/railing_2.jpg';
import slide3 from '../../assets/img/custom/railing/railing_3.jpg';
import slide4 from '../../assets/img/custom/railing/railing_4.jpg';
import slide5 from '../../assets/img/custom/railing/railing_5.jpg';
import slide6 from '../../assets/img/custom/railing/glass_1.jpg';
import slide7 from '../../assets/img/custom/railing/glass_2.jpg';
import slide8 from '../../assets/img/custom/railing/glass_3.jpg';
import slide9 from '../../assets/img/custom/railing/glass_4.jpg';
import slide10 from '../../assets/img/custom/railing/glass_5.jpg';
import slide11 from '../../assets/img/custom/railing/fence_1.jpg';
import slide12 from '../../assets/img/custom/railing/fence_2.jpg';
import slide13 from '../../assets/img/custom/railing/fence_3.jpg';
import slide14 from '../../assets/img/custom/railing/fence_4.jpg';
import slide15 from '../../assets/img/custom/railing/fence_5.jpg';
import slide16 from '../../assets/img/custom/railing/gate_1.jpg';
import slide17 from '../../assets/img/custom/railing/gate_2.jpg';
import slide18 from '../../assets/img/custom/railing/gate_3.jpg';
import slide19 from '../../assets/img/custom/railing/gate_4.jpg';
import slide20 from '../../assets/img/custom/railing/gate_5.jpg';
import slide21 from '../../assets/img/custom/railing/patio_1.jpg';
import slide22 from '../../assets/img/custom/railing/patio_2.jpg';
import slide23 from '../../assets/img/custom/railing/patio_3.jpg';
import slide24 from '../../assets/img/custom/railing/patio_4.jpg';
import slide25 from '../../assets/img/custom/railing/patio_5.jpg';
import slide26 from '../../assets/img/custom/railing/rail_1.jpg';
import slide27 from '../../assets/img/custom/railing/rail_2.jpg';
import slide28 from '../../assets/img/custom/railing/rail_3.jpg';
import slide29 from '../../assets/img/custom/railing/rail_4.jpg';
import slide30 from '../../assets/img/custom/railing/rail_5.jpg';
import slide31 from '../../assets/img/custom/railing/stair_1.jpg';
import slide32 from '../../assets/img/custom/railing/stair_2.jpg';
import slide33 from '../../assets/img/custom/railing/stair_3.jpg';
import slide34 from '../../assets/img/custom/railing/stair_4.jpg';
import slide35 from '../../assets/img/custom/railing/stair_5.jpg';
import slide36 from '../../assets/img/custom/railing/job_1.jpg';
import slide37 from '../../assets/img/custom/railing/job_2.jpg';
import slide38 from '../../assets/img/custom/railing/job_3.jpg';
import slide39 from '../../assets/img/custom/railing/job_4.jpg';
import slide40 from '../../assets/img/custom/railing/job_5.jpg';
import slide41 from '../../assets/img/custom/railing/railing_6.jpg';
import slide42 from '../../assets/img/custom/railing/railing_7.jpg';
import slide43 from '../../assets/img/custom/railing/railing_8.jpg';
import slide44 from '../../assets/img/custom/railing/railing_9.jpg';
import slide45 from '../../assets/img/custom/railing/railing_10.jpg';
import slide46 from '../../assets/img/custom/railing/glass_6.jpg';
import slide47 from '../../assets/img/custom/railing/glass_7.jpg';
import slide48 from '../../assets/img/custom/railing/glass_8.jpg';
import slide49 from '../../assets/img/custom/railing/glass_9.jpg';
import slide50 from '../../assets/img/custom/railing/glass_10.jpg';
import slide51 from '../../assets/img/custom/railing/fence_6.jpg';
import slide52 from '../../assets/img/custom/railing/fence_7.jpg';
import slide53 from '../../assets/img/custom/railing/gate_6.jpg';
import slide54 from '../../assets/img/custom/railing/gate_7.jpg';
import slide55 from '../../assets/img/custom/railing/gate_8.jpg';
import slide56 from '../../assets/img/custom/railing/gate_9.jpg';
import slide57 from '../../assets/img/custom/railing/gate_10.jpg';
import slide58 from '../../assets/img/custom/railing/patio_6.jpg';
import slide59 from '../../assets/img/custom/railing/patio_7.jpg';
import slide60 from '../../assets/img/custom/railing/patio_8.jpg';
import slide61 from '../../assets/img/custom/railing/patio_9.jpg';
import slide62 from '../../assets/img/custom/railing/patio_10.jpg';
import slide63 from '../../assets/img/custom/railing/rail_6.jpg';
import slide64 from '../../assets/img/custom/railing/rail_7.jpg';
import slide65 from '../../assets/img/custom/railing/rail_8.jpg';
import slide66 from '../../assets/img/custom/railing/rail_9.jpg';
import slide67 from '../../assets/img/custom/railing/rail_10.jpg';
import slide68 from '../../assets/img/custom/railing/stair_6.jpg';
import slide69 from '../../assets/img/custom/railing/stair_7.jpg';
import slide70 from '../../assets/img/custom/railing/stair_8.jpg';
import slide71 from '../../assets/img/custom/railing/stair_9.jpg';
import slide72 from '../../assets/img/custom/railing/stair_10.jpg';
import slide73 from '../../assets/img/custom/railing/job_6.jpg';
import slide74 from '../../assets/img/custom/railing/job_7.jpg';
import slide75 from '../../assets/img/custom/railing/job_8.jpg';
import slide76 from '../../assets/img/custom/railing/job_9.jpg';
import slide77 from '../../assets/img/custom/railing/job_10.jpg';



const servicesOneData = [
    {
        id: uuidv4(),
        bg: bg1,
        icon: 'blueprint',
        title: 'Aluminium  railing',
        desc: `Experience exceptional craftsmanship and durability with our Aluminium railing.`,
        images: [slide1, slide2, slide3, slide4, slide5, slide41, slide42, slide43, slide44, slide45, ],
    },

    {
        id: uuidv4(),
        bg: bg1,
        icon: 'blueprint',
        title: 'Glass railing',
        desc: `Transform your space with elegance and transparency through our Glass railing`,
        images: [slide6, slide7, slide8, slide9, slide10, slide46, slide47, slide48, slide49, slide50],
    },

    {
        id: uuidv4(),
        bg: bg1,
        icon: 'blueprint',
        title: 'Fence',
        desc: `Secure your property with our fencing service, offering high-quality materials.`,
        images: [slide11, slide12, slide13, slide14, slide15, slide51, slide52]
    },

    {
        id: uuidv4(),
        bg: bg1,
        icon: 'blueprint',
        title: 'Gate',
        desc: `Enhance the entrance to your property with our custom gate solutions.`,
        images: [slide16, slide17, slide18, slide19, slide20,  slide53, slide54, slide55, slide56, slide57]

    },

    {
        id: uuidv4(),
        bg: bg1,
        icon: 'blueprint',
        title: 'Patio & Awning',
        desc: `Enjoy the outdoors in style with our exquisite patio and awning installations.`,
        images: [slide21, slide22, slide23, slide24, slide25, slide58, slide59, slide60, slide61, slide62]

    },

    {
        id: uuidv4(),
        bg: bg1,
        icon: 'blueprint',
        title: 'Handrails',
        desc: `Ensure safety and elegance with our handrail installations.`,
        images: [slide26, slide27, slide28, slide29, slide30, slide63, slide64, slide65, slide66, slide67]

    },
    {
        id: uuidv4(),
        bg: bg1,
        icon: 'blueprint',
        title: 'Aluminum steps & stairs',
        desc: `Upgrade your property with our expertly crafted aluminum steps and stairs.`,
        images: [slide31, slide32, slide33, slide34, slide35, slide68, slide69, slide70, slide71, slide72]

    },
    {
        id: uuidv4(),
        bg: bg1,
        icon: 'blueprint',
        title: 'Customized jobs',
        desc: `Bring your unique vision to life with our exceptional custom job service.`,
        images: [slide36, slide37, slide38, slide39, slide40, slide73, slide74, slide75, slide76, slide77]

    },

]




export default servicesOneData;