import { v4 as uuidv4 } from 'uuid';
import img1 from '../../assets/img/team/vikramjit.jpeg';
import img2 from '../../assets/img/team/sharan.jpeg';
import img3 from '../../assets/img/team/avi.jpeg';
import img4 from '../../assets/img/team/sarb.jpeg';
import img5 from '../../assets/img/team/rahul.jpeg';
import img6 from '../../assets/img/team/1.png'

const teamThreeData = [
    {
        id: uuidv4(),
        img: img1,
        name: 'Vikramjit Singh (Vik)',
        designation: 'Owner and Managing Director',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: '#'
    },

    {
        id: uuidv4(),
        img: img2,
        name: 'Sharan',
        designation: 'Marketing advisor',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: '#'
    },

    {
        id: uuidv4(),
        img: img3,
        name: 'Avi',
        designation: 'Drafter',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: '#'
    },

    {
        id: uuidv4(),
        img: img4,
        name: 'Sarb',
        designation: 'Team member',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: '#'
    },

    {
        id: uuidv4(),
        img: img5,
        name: 'Rahul Kumar',
        designation: 'Team member',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: '#'
    },

    {
        id: uuidv4(),
        img: img6,
        name: 'Paras Thakur',
        designation: 'Team member',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: '#'
    },

]





export default teamThreeData;