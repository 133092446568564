import React from 'react'
import { Link } from 'react-router-dom';
import aboutImg from '../../assets/img/custom/aboutnew.jpeg';

const AboutTwo = () => {
    return (
        <section className="about-section section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 col-12 pe-xl-0">
                        <div className="about-cover-bg bg-cover me-xl-5" style={{ backgroundImage: `url(${aboutImg})` }}>
                            <div className="our-experience-years">
                                <div className="year-outline">
                                    <h2>5</h2>
                                </div>
                                <p>Years <span>Experience</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 mt-5 mt-lg-0 col-12">
                        <div className="block-contents">
                            <div className="section-title">
                                <span>About Company</span>
                                <h2>We Always Think On Your Dream</h2>
                            </div>
                            <blockquote>what railings have a significant impact on the value of your house-”we know”</blockquote>
                        </div>

                        <p>
                            F5 alum is a dedicated company with an experience of four years in the field of railing , awning and all the other aluminum custom jobs. As per the customer demand after our market surve, we came to know that customers need a single platform where they can get multiple services. Thus , we decided to bring your precious dreams into actions. As a promising service providers, you can count on great quality of material, genuine price and great services.
                        </p>

                        <Link to="/about" className="theme-btn mt-35">read more</Link>
                    </div>
                </div>
            </div>
            <h2 className="section-rotate-title d-none d-xxl-block">ABOUT</h2>
        </section>
    )
}

export default AboutTwo