import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import axios from "axios";
import { Alert, Button } from "react-bootstrap";
import { useState } from "react";

const ContactPageContents = () => {
    const [show, setShow] = useState(false);
    const ContactSchema = Yup.object().shape({
        Name: Yup.string()
            .min(2, 'Too Short!')
            .max(70, 'Too Long!')
            .required('This Field is Required'),
        Email: Yup.string()
            .email('Invalid email')
            .required('This Field is Required'),
        Mobile: Yup.string()
            .min(2, 'Too Short!')
            .max(70, 'Too Long!')
            .required('This Field is Required'),
        Message: Yup.string()
            .min(2, 'Too Short!')
            .max(70, 'Too Long!')
            .required('This Field is Required'),
    });

    const handleValidSubmit = (values) => {
        console.log(values);
        const body = {
            name: values.Name,
            email: values.Email,
            mobile: values.Mobile,
            message: values.Message,
        };
        axios({
            method: "post",
            url: `https://f5alum.ca/contact.php`,
            headers: { "content-type": "application/json" },
            data: body,
        })
            .then((result) => {
                console.log(result);
            })
            .catch((error) => console.log(error));
        console.log(values);
        const form = document.forms["contact-form"];

        fetch(
            `https://script.google.com/macros/s/AKfycbxBVW6ycfaD8JXrOgrKTinivBjLM8mGtKu2Rpj7_FLRqpQbuFt1vQ5ORL5QMt6ZGq_M/exec`,
            { method: "POST", body: new FormData(form) }
        )
            .then((res) => {
                if (res.status === 200) {
                    console.log(res);
                    setShow(true)

                }
            })
            .catch((error) => console.error("Error!", error.message));
    };
    return (
        <div className="contact-us-wrapper section-padding">
            <div className="container">
                <div className="row eq-height">
                    <div className="col-lg-8 col-12">
                        <div className="contact-form">
                            <h2>Get in Touch</h2>
                            <Formik
                                onSubmit={(values) => { handleValidSubmit(values) }}
                                initialValues={{
                                    Name: '',
                                    Email: '',
                                    Mobile: '',
                                    Message: ''
                                }}
                                validationSchema={ContactSchema}
                            >
                                {({ errors, touched }) => (
                                    <Form
                                        name="contact-form"
                                        className="contactFrom"
                                    >

                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <Field
                                                    name="Name"
                                                    className="single-personal-info"
                                                    required
                                                    placeholder="Full Name"
                                                />
                                                <ErrorMessage render={msg => <div className="invalid-feedback">*{msg}</div>} name="Name" />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <Field
                                                    name="Email"
                                                    className="single-personal-info"
                                                    type="email"
                                                    required
                                                    placeholder="Email*"
                                                />
                                                <ErrorMessage render={msg => <div className="invalid-feedback">*{msg}</div>} name="Email" />
                                            </div>
                                            <div className="col-md-12 col-12">

                                                <Field
                                                    name="Mobile"
                                                    className="single-personal-info"
                                                    type="text"
                                                    required
                                                    placeholder="Mobile*"
                                                />
                                                <ErrorMessage render={msg => <div className="invalid-feedback">*{msg}</div>} name="Mobile" />
                                            </div>
                                            <div className="col-lg-12 mb-2 col-sm-12">
                                                <Field
                                                    name="Message"
                                                    className="single-personal-info"
                                                    as="textarea"
                                                    rows="4"
                                                    placeholder="Message*"
                                                />
                                                <ErrorMessage render={msg => <div className="invalid-feedback">*{msg}</div>} name="Message" />
                                            </div>
                                        </div>

                                        {/* <div className="row">
                                        <div className="col-md-6 col-12">
                                            <Field
                                                name="Name"
                                                className="single-personal-info"
                                                required
                                                placeholder="Full Name"
                                            />
                                            <ErrorMessage render={msg => <div className="invalid-feedback">*{msg}</div>} name="Name" />
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <Field
                                                name="Email"
                                                className="single-personal-info"
                                                type="email"
                                                required
                                                placeholder="Email*"
                                            />
                                            <ErrorMessage render={msg => <div className="invalid-feedback">*{msg}</div>} name="Email" />
                                        </div>
                                        <div className="col-md-6 col-12">

                                            <Field
                                                name="Mobile"
                                                className="single-personal-info"
                                                type="text"
                                                required
                                                placeholder="Mobile*"
                                            />
                                            <ErrorMessage render={msg => <div className="invalid-feedback">*{msg}</div>} name="Mobile" />
                                        </div>
                                        <div className="col-lg-12 mb-2 col-sm-12">
                                            <Field
                                                name="Message"
                                                className="single-personal-info"
                                                as="textarea"
                                                rows="4"
                                                placeholder="Message*"
                                            />
                                            <ErrorMessage render={msg => <div className="invalid-feedback">*{msg}</div>} name="Message" />
                                        </div>
                                    </div> */}
                                        <Button type="submit" className="submit-btn">
                                            <span>Send Message</span></Button>
                                    </Form>
                                )}
                            </Formik>
                            <div className="col-lg-12 mt-4 col-sm-12">
                                <Alert show={show} variant="success" onClose={() => setShow(false)} dismissible>
                                    <Alert.Heading> Our team will contact you soon!</Alert.Heading>
                                </Alert>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12">
                        <div className="contact-us-sidebar mt-5 mt-lg-0">
                            <div className="contact-info">
                                <h2>CONTACT INFO</h2>
                                <div className="single-info">
                                    <div className="icon">
                                        <i className="flaticon-email" />
                                    </div>
                                    <div className="text">
                                        <span>Email Us</span>
                                        <h5>info@f5alum.ca</h5>
                                    </div>
                                </div>
                                <div className="single-info">
                                    <div className="icon">
                                        <i className="flaticon-phone-call-1" />
                                    </div>
                                    <div className="text">
                                        <span>Call Us</span>
                                        <h5>+1 604-500-4006</h5>
                                    </div>
                                </div>
                                <div className="single-info">
                                    <div className="icon">
                                        <i className="flaticon-pin" />
                                    </div>
                                    <div className="text">
                                        <span>Location</span>
                                        <h5>Lower mainland, British Columbia,canada</h5>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ContactPageContents;