import React from 'react'
import { Link } from 'react-router-dom';
import bannerBg from '../../assets/img/custom/servicde_1.jpg';



const ServicesCta = () => {
    return (
        <section className="pricing-cta-wrapper text-white bg-cover bg-center section-padding" style={{ backgroundImage: `url(${bannerBg})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 ps-xl-0 pe-xl-0">
                        <div className="pricing-contents">
                            <h1>We Offer The Renovating Services</h1>
                            <div className="btn-wrapper d-flex align-items-end justify-content-center">
                                <Link to={'/contact'} >Contact Us</Link>
                                <div className="btn-cta">
                                    <div className="icon">
                                        <i className="flaticon-24-hours" />
                                    </div>
                                    <div className="content">
                                        <span className="text-star">Call for support</span>
                                        <h3>(+60)4500-4006</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ServicesCta