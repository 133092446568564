import { isValidElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

const faqOneData = [
    {
        id: uuidv4(),
        ques: 'What services does your aluminum construction company offer?',
        ans: 'We specialize in aluminum railing, glass railing, fence, gate, patio & awning, handrails, aluminum steps & stairs, and customized jobs.',
        index: 'faq1',
    },

    {
        id: uuidv4(),
        ques: 'What types of railings do you provide?',
        ans: 'We offer both aluminum and glass railings for various applications.',
        index: 'faq2'
    },

    {
        id: uuidv4(),
        ques: 'Can you handle customized projects?',
        ans: 'Yes, we excel in providing tailor-made solutions to meet individual project requirements.',
        index: 'faq3'
    },

    {
        id: uuidv4(),
        ques: 'Are your aluminum products durable?',
        ans: 'Absolutely, our aluminum products are known for their long-lasting durability.',
        index: 'faq4'
    },

    {
        id: uuidv4(),
        ques: 'Do you provide installation services?',
        ans: 'Yes, we offer professional installation services for all our products.',
        index: 'faq5'
    },

]

export default faqOneData;