import React from 'react'
import FooterFour from '../Components/Footer/FooterFour'
import HeaderOne from '../Components/Header/HeaderOne'
import bannerBg from '../assets/img/custom/about_banner.jpg';
import PageBanner from '../Components/PageBanner';
import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import ContactPageContents from "../Components/Contact/ContactPageContents";

const ContactPage = () => {
  return (
    <>
      <HeaderOne />
      <PageBanner title='Contact Us' bannerBg={bannerBg} currentPage='contact' />
      <ContactPageContents />
      {/* <BrandCarouselThree /> */}
      <FooterFour />
    </>
  )
}

export default ContactPage;
