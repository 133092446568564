import faqVideoBg from '../../assets/img/custom/services_3.jpg';
import 'react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video';
import { useState } from 'react';


const FaqOneVideo = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>

            <div className="video-popup-wrapper d-flex justify-content-center align-items-center bg-cover bg-center" style={{ backgroundImage: `url(${faqVideoBg})` }}>

            </div>
            <div className="box-cta-call d-flex align-items-center justify-content-between">
                <div className="icon">
                    <i className="flaticon-24-hours"></i>
                </div>
                <div className="content-text">
                    <span>Call for support</span>
                    <h3>(+60)4500-4006</h3>
                </div>
            </div>
        </>
    )
}

export default FaqOneVideo;