// import Modal from 'react-bootstrap/Modal';
import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-modal';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

Modal.setAppElement('#root');

const GalleryModal = forwardRef(({ show, handleClose, images, heading }, ref) => {

    const modalRef = useRef()
    useImperativeHandle(ref, () => modalRef.current, [modalRef])

    const customStyles = {
        content: {
            height: "90vh",
            width: "90vw",
            zIndex: "9999999999",
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    return (
        <>
            <Modal
                // onAfterOpen={() => {
                //     console.log('modalRef', modalRef);
                //     disableBodyScroll(modalRef.current)
                // }}
                // onAfterClose={() => {
                //     console.log(modalRef.current);
                //     enableBodyScroll(modalRef.current)
                // }}
                // ref={modalRef}
                isOpen={show} onRequestClose={handleClose}
                style={customStyles}
                contentLabel={heading}
            >

                <Carousel >
                    {images?.map(img => (
                        <Carousel.Item key={img}>
                            <img
                                className="d-block gallery-img-cstm"
                                src={img}
                                alt="First slide"
                            />
                        </Carousel.Item>
                    ))}
                </Carousel>
                {/* <Modal.Header closeButton> */}
                {/* <Modal.Title>{heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel>
                        {images?.map(img => (
                            <Carousel.Item key={img}>
                                <img
                                    className="d-block w-100"
                                    src={img}
                                    alt="First slide"
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body> */}

            </Modal>
        </>
    )
})

export default GalleryModal
