import AboutTwo from "../Components/About/AboutTwo";
import FeaturesOne from "../Components/Features/FeaturesOne";
import FooterFour from "../Components/Footer/FooterFour";
import HeaderOne from "../Components/Header/HeaderOne";
import HeroOne from "../Components/Hero/HeroOne";
import HeroSocials from "../Components/Hero/HeroSocials";
import ServicesOne from "../Components/Services/ServicesOne";
import OurSkill from "../Components/Skills/OurSkill";
const HomeOne = () => {
    return (
        <>
            <HeaderOne />
            <HeroSocials />
            <HeroOne />
            <AboutTwo />
            <ServicesOne />
            <FeaturesOne />
            <OurSkill />
            <FooterFour />
        </>
    )
}

export default HomeOne;