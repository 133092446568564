import { Swiper, SwiperSlide } from "swiper/react";
import slide1 from '../../assets/img/custom/gate.jpeg';
import slide2 from '../../assets/img/custom/stair.jpeg';
import slide3 from '../../assets/img/custom/cust_railing.jpeg';
import slide4 from '../../assets/img/custom/fence.jpeg';
import slide5 from '../../assets/img/custom/handrails.jpeg';
import slide6 from '../../assets/img/custom/com_railing.jpeg';
import slide7 from '../../assets/img/custom/glass_patio.jpeg';
import slide8 from '../../assets/img/custom/railing.jpeg';
import slide9 from '../../assets/img/custom/glass_railing.jpeg';
import slide11 from '../../assets/img/custom/patio.jpeg';
import slide12 from '../../assets/img/custom/alu_railing.jpeg';
import slide13 from '../../assets/img/custom/patio_cover.jpeg';

import { Link } from 'react-router-dom';

import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper";


const HeroOne = () => {
    return (
        <section className="hero-wrapper slider-custom hero-1 ">
            <Swiper navigation={true} autoplay={true} modules={[Navigation, Autoplay]} loop>
                <SwiperSlide className=" bg-cover" style={{ backgroundImage: `url(${slide1})` }} >
                    <div className="container">
                        <div className="row">
                            <div className="col-12  pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                                <div className="hero-contents pe-lg-3">
                                    <h1 className="fs-lg wow fadeInLeft animated" data-wow-duration="1.3s" >GATE</h1>
                                    {/* <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">we make professionally aluminum step stairs which is non-slippery and available in any custom color</p> */}

                                    <Link to="/contact" className="theme-btn mt-35">Contact</Link>
                                    {/* <a href="contact.html" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">contact us</a> */}
                                    {/* <a href="about.html" className="plus-text-btn wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".6s">
                                        <div className="icon">
                                            <i className="fas fa-plus"></i>
                                        </div>
                                        <div className="link-text">
                                            <span>Explore</span> More about Us
                                        </div>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className=" bg-cover" style={{ backgroundImage: `url(${slide2})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12  pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                                <div className="hero-contents pe-lg-3">
                                    <h1 className="fs-lg wow fadeInLeft animated" data-wow-duration="1.3s">Aluminum Stairs </h1>
                                    {/* <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">all types of railings-picket and glass are available according to our customer demand. </p> */}
                                    <a href="contact.html" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">contact us</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className=" bg-cover" style={{ backgroundImage: `url(${slide3})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                                <div className="hero-contents pe-lg-3">
                                    <h1 className="fs-lg wow fadeInLeft animated" data-wow-duration="1.3s">Customized Railing</h1>
                                    {/* <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">we have a wide variety of awning or canopy - glass and vpan kind of material are available at your service</p> */}
                                    <a href="contact.html" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">contact us</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className=" bg-cover" style={{ backgroundImage: `url(${slide4})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                                <div className="hero-contents pe-lg-3">
                                    <h1 className="fs-lg wow fadeInLeft animated" data-wow-duration="1.3s">Fence </h1>
                                    {/* <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">we have a wide variety of awning or canopy - glass and vpan kind of material are available at your service</p> */}
                                    <a href="contact.html" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">contact us</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className=" bg-cover" style={{ backgroundImage: `url(${slide5})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                                <div className="hero-contents pe-lg-3">
                                    <h1 className="fs-lg wow fadeInLeft animated" data-wow-duration="1.3s">Handrails</h1>
                                    {/* <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">we have a wide variety of awning or canopy - glass and vpan kind of material are available at your service</p> */}
                                    <a href="contact.html" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">contact us</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className=" bg-cover" style={{ backgroundImage: `url(${slide6})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                                <div className="hero-contents pe-lg-3">
                                    <h1 className="fs-lg wow fadeInLeft animated" data-wow-duration="1.3s">Commercial Railing</h1>
                                    {/* <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">we have a wide variety of awning or canopy - glass and vpan kind of material are available at your service</p> */}
                                    <a href="contact.html" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">contact us</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className=" bg-cover" style={{ backgroundImage: `url(${slide7})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                                <div className="hero-contents pe-lg-3">
                                    <h1 className="fs-lg wow fadeInLeft animated" data-wow-duration="1.3s">Glass Patio</h1>
                                    {/* <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">we have a wide variety of awning or canopy - glass and vpan kind of material are available at your service</p> */}
                                    <a href="contact.html" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">contact us</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className=" bg-cover" style={{ backgroundImage: `url(${slide8})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                                <div className="hero-contents pe-lg-3">
                                    <h1 className="fs-lg wow fadeInLeft animated" data-wow-duration="1.3s">Railing</h1>
                                    {/* <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">we have a wide variety of awning or canopy - glass and vpan kind of material are available at your service</p> */}
                                    <a href="contact.html" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">contact us</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className=" bg-cover" style={{ backgroundImage: `url(${slide9})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                                <div className="hero-contents pe-lg-3">
                                    <h1 className="fs-lg wow fadeInLeft animated" data-wow-duration="1.3s">glass Railing</h1>
                                    {/* <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">we have a wide variety of awning or canopy - glass and vpan kind of material are available at your service</p> */}
                                    <a href="contact.html" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">contact us</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className=" bg-cover" style={{ backgroundImage: `url(${slide11})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 ps-md-4 pe-md-5 col-xxl-10 col-lg-10 col-md-8 col-sm-10">
                                <div className="hero-contents pe-lg-3">
                                    <h1 className="fs-lg wow fadeInLeft animated" data-wow-duration="1.3s">Black Insulated patio</h1>
                                    {/* <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">we have a wide variety of awning or canopy - glass and vpan kind of material are available at your service</p> */}
                                    <a href="contact.html" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">contact us</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className=" bg-cover" style={{ backgroundImage: `url(${slide12})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                                <div className="hero-contents pe-lg-3">
                                    <h1 className="fs-lg wow fadeInLeft animated" data-wow-duration="1.3s">Aluminum Railing</h1>
                                    {/* <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">we have a wide variety of awning or canopy - glass and vpan kind of material are available at your service</p> */}
                                    <a href="contact.html" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">contact us</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className=" bg-cover" style={{ backgroundImage: `url(${slide13})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                                <div className="hero-contents pe-lg-3">
                                    <h1 className="fs-lg wow fadeInLeft animated" data-wow-duration="1.3s">Patio Cover</h1>
                                    {/* <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">we have a wide variety of awning or canopy - glass and vpan kind of material are available at your service</p> */}
                                    <a href="contact.html" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">contact us</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    )
}

export default HeroOne;