const HeroSocials = () => {
    return (
        <>
            <div className="hero-social-elements d-none d-xl-block">
                <div className="flp-text">
                    <p>Follow Us</p>
                </div>
                <div className="long-arrow"></div>
                <div className="social-link">
                    <a href="https://www.facebook.com/people/F5-Alum-Rail-Awning-Inc/100091804038906/?mibextid=LQQJ4d" target="_blank"><i className="fab fa-facebook-f" /></a>
                    <a href="https://www.instagram.com/f5alumrail/?igshid=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank"><i className="fab fa-instagram" /></a>
                    <a href="https://www.tiktok.com/@f5.alum.rail?_t=8dB9YOzmyBR&_r=1" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" /></svg></a>

                </div>
            </div>
        </>
    )
}

export default HeroSocials