import videoBg from '../../assets/img/video_bg_1.jpg';
import imgBlock1 from '../../assets/img/custom/about_1.jpg';
import tabImg1 from '../../assets/img/home1/tab-img.jpg'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import 'react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video';


const AboutOne = ({ pt }) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="EZ9DrY43wtw"
                onClose={() => setOpen(false)}
            />
            <section className={pt ? 'about-section section-padding' : 'about-section section-padding pt-0'}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5 col-12">
                            <div className="img-block-pop-video">
                                <img src={imgBlock1} alt="F5 Alum" />
                                {/* <div className="popup-video-block d-flex justify-content-center align-items-center bg-cover" style={{ backgroundImage: `url(${videoBg})` }}>
                                    <div className="video-play-btn">
                                        <span className="popup-video" onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}><i className="fas fa-play"></i></span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 col-md-10 col-12">
                            <div className="block-contents">
                                <div className="section-title">
                                    <span>01. About Company</span>
                                    <h2>creative building design company</h2>
                                </div>
                                <p>F5 alum is a dedicated company with an experience of four years in the field of railing , awning and all the other aluminum custom jobs. As per the customer demand after our market surve,we came to know that customers need a single platform where they can get multiple services. Thus , we decided to bring your precious dreams into actions. As a promising service providers, you can count on great quality of material, genuine price and great services</p>
                            </div>
                            <div className="tab-content-block">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-technology-tab" data-bs-toggle="pill" data-bs-target="#pills-technology" type="button" role="tab" aria-controls="pills-technology" aria-selected="true">Technology</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-materials-tab" data-bs-toggle="pill" data-bs-target="#pills-materials" type="button" role="tab" aria-controls="pills-materials" aria-selected="false">Materials</button>
                                    </li>

                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-technology" role="tabpanel" aria-labelledby="pills-technology-tab">
                                        <div className="tab-inner-contents">

                                            <div className="checked-features-list">
                                                <ul>
                                                    <li>Design and drafting</li>
                                                    <li>Precision machining</li>
                                                    <li>Engineered products</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-materials" role="tabpanel" aria-labelledby="pills-materials-tab">
                                        <div className="tab-inner-contents">

                                            <div className="checked-features-list">
                                                <ul>
                                                    <li>Sustainable and durable material</li>
                                                    <li>Low maintenance and longevity of products</li>
                                                    <li>Powder coating</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutOne;