import { Link } from "react-router-dom";
import logoImg from '../../assets/img/logo_white.png';

const FooterFour = () => {
    return (
        <>
            <footer className="footer-4 footer-wrap ">
                <div className="footer-widgets-wrapper text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-sm-6 col-12">
                                <div className="footer-site-info me-lg-5 mt-40">
                                    <Link to='/'><img src={logoImg} alt="F5 Alum" /></Link>

                                </div>
                            </div>

                            <div className="col-sm-6 col-xl-2 offset-xl-1  col-12">
                                <div className="single-footer-wid">
                                    <div className="wid-title">
                                        <h3>Quick Links</h3>
                                    </div>
                                    <ul>
                                        <li><Link to="/about">About Company</Link></li>
                                        <li><Link to="/services">Services</Link></li>
                                        {/* <li><Link to="/services">Internet of Things</Link></li>
                                        <li><Link to="/services">Product Development</Link></li>
                                        <li><Link to="/services">Industrial Automation</Link></li> */}
                                        <li><Link to="/contact">Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3 offset-xl-1 col-12">
                                <div className="single-footer-wid newsletter_widget style-2">
                                    <div className="wid-title">
                                        <h3>Contact Us</h3>
                                    </div>
                                    <div className="newsletter_box">
                                        <ul>
                                            <li>
                                                <a href="tel:123-456-789">+1 604-500-4006</a>
                                            </li>
                                            <li>
                                                <a href="mailto:info@f5alum">info@f5alum.ca</a>
                                            </li>
                                            <li>
                                                <address >Lower mainland, British Columbia,canada</address>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 order-3 mt-3 mt-lg-0 order-lg-1 col-12 text-center text-md-start">
                                <div className="copyright-info">
                                    <p>© {new Date().getFullYear()} Copyright By <Link to="/">F5Alum</Link>. All Rights Reserved</p>
                                </div>
                            </div>
                            <div className="col-lg-6 text-center order-1 order-lg-2 col-12">
                                <div className="footer-social">
                                    <a href="https://www.facebook.com/people/F5-Alum-Rail-Awning-Inc/100091804038906/?mibextid=LQQJ4d" target="_blank"><i className="fab fa-facebook-f" /></a>
                                    <a href="https://www.instagram.com/f5alumrail/?igshid=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank"><i className="fab fa-instagram" /></a>
                                    <a href="https://www.tiktok.com/@f5.alum.rail?_t=8dB9YOzmyBR&_r=1" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" className="tiktok-icon" height="1em" viewBox="0 0 448 512"><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" /></svg></a>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default FooterFour;