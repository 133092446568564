import { React, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import servicesOneData from "./servicesOneData";

import GalleryModal from '../GalleryModal/GalleryModal';
const ServicesOne = () => {
    const [show, setShow] = useState(false);
    const [heading, setHeading] = useState('');

    const [images, setImages] = useState([])
    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => setShow(true)

    const updateImages = (element) => {
        setImages(element.images)
        handleShow()
        setHeading(element.title)
    }

    return (
        <section className="our-service-wrapper section-padding bg-gray">
            <div className="container">
                <div className="row mtm-30">
                    <div className="col-md-6 col-12 col-lg-4 mt-30">
                        <div className="block-contents">
                            <div className="section-title">
                                <span>02. Featured Service</span>
                                <h2>What We Offer You</h2>
                            </div>
                            <p>Our promise as a contractor is to build strong community value into every projects</p>
                        </div>
                    </div>
                    {
                        servicesOneData.map((data) => (
                            <div className="col-md-6 col-12 col-lg-4 mt-30" key={data.id}>
                                <div onClick={(event) => { event.preventDefault(); updateImages(data) }} className="single-service-box style-1">
                                    <div className="service-bg bg-cover" style={{ backgroundImage: `url(${data.bg})` }}></div>
                                    <div className="icon">
                                        <i className={`flaticon-${data.icon}`}></i>
                                    </div>
                                    <div className="contents">
                                        <h4>{data.title}</h4>
                                        <p>{data.desc}</p>
                                    </div>
                                </div>
                                {/* <div className="single-service-box style-1">
                                    <div className="service-bg bg-cover" style={{ backgroundImage: `url(${data.bg})` }}></div>
                                    <div className="icon">
                                        <i className={`flaticon-${data.icon}`}></i>
                                    </div>
                                    <div className="contents">
                                        <h4>{data.title}</h4>
                                        <p>{data.desc}</p>
                                    </div>
                                </div> */}
                            </div>
                        ))
                    }
                    <GalleryModal heading={heading} images={images} show={show} handleClose={handleClose} />

                </div>
            </div>
        </section>
    )
}

export default ServicesOne;