import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import portfolioTwoData from "./portfolioTwoData";
import { Link } from "react-router-dom";
import sectionBg from '../../assets/img/project-bg.jpg';
import { Navigation } from "swiper";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useState } from "react";
import servicesOneData from "../Services/servicesOneData";
import compressedFile from '../../assets/img/custom/Archive.zip'
const PortfolioTwo = () => {
    const [key, setKey] = useState('Aluminium  railing');
    const [element, setElement] = useState(servicesOneData[0])
    const setMedia = (key) => {
        const selectedData = servicesOneData.filter(element => element.title === key)
        setElement(selectedData[0])
    }
    return (
        <section className="project-carousel-wrapper bg-cover section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-8">
                        <div className="block-contents">
                            <div className="section-title">
                                <h5 className="textleft text-white">Project</h5>
                                <span>Recent Portfolio</span>
                                <h2 className="text-white">Let's See recent Projects</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <Tabs
                    defaultActiveKey="Aluminium  railing"
                    id="justify-tab-example"
                    onSelect={(k) => { setMedia(k); setKey(k) }}
                    className="mb-3 swiper-tabs"
                    fill
                >
                    {servicesOneData.map(data => (
                        <Tab key={data.id} eventKey={data.title} title={data.title}>
                            <div className="project-carousel-card-active text-white">
                                <Swiper
                                    slidesPerView={3}
                                    navigation
                                    modules={[Navigation]}
                                    breakpoints={{
                                        "@0.00": {
                                            slidesPerView: 1,
                                            spaceBetween: 20,

                                        },
                                        "@0.75": {
                                            slidesPerView: 2,
                                            spaceBetween: 20,

                                        },
                                        "@1.00": {
                                            slidesPerView: 2,
                                            spaceBetween: 20,

                                        },
                                        "@1.50": {
                                            slidesPerView: 3,
                                            spaceBetween: 20,
                                        },
                                    }}
                                >
                                    {
                                        data.images.map((url, i) => (
                                            <SwiperSlide className="single-project-card" key={url}>
                                                <div className="project-thumb bg-cover" style={{ backgroundImage: `url(${url})` }}
                                                ></div>
                                                {/* <div className="contents">
                                                <div className="project-number">
                                                    <h2>{i + 1}</h2>
                                                </div>
                                                <div className="project-details">
                                                    <span>{data.title}</span>
                                                </div>
                                            </div> */}
                                            </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                            </div></Tab>
                    ))}

                </Tabs>

                <div className="row dwnld-btn">
                    <div className=" col-12">
                        <div className="block-contents">
                            <a href={compressedFile} download="f5-alum-all-pictures" target='_blank'>
                                <button className="theme-btn mt-35">Download All Images</button>
                            </a>
                            <a href={compressedFile} download="f5-alum-all-pictures" target='_blank'>
                                <button className="theme-btn ms-md-4 mt-35">Download {key} Images</button>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default PortfolioTwo;