import React from 'react'
import FooterFour from '../Components/Footer/FooterFour'
import HeaderOne from '../Components/Header/HeaderOne'
import bannerBg from '../assets/img/custom/about_banner.jpg';
import PageBanner from '../Components/PageBanner';
import ServicesOne from "../Components/Services/ServicesOne";
import ServicesCta from "../Components/Services/ServicesCta";
import BestFeatures from "../Components/Features/BestFeatures";
import FaqOne from "../Components/Faq/FaqOne";
import PortfolioTwo from '../Components/Portfolio/PortfolioTwo';
const ServicePage = () => {
  return (
    <>
      <HeaderOne />
      <PageBanner title='Our Services' bannerBg={bannerBg} currentPage='Service' />
      <ServicesOne />
      <ServicesCta />
      <BestFeatures />
      <PortfolioTwo />
      <FaqOne />
      <FooterFour />
    </>
  )
}

export default ServicePage