import { useState } from "react";
import { Link } from "react-router-dom";
import mainLogo from '../../assets/img/logo.svg';
import { ReactComponent as ReactLogo } from '../../assets/img/logo.svg';

import MobileMenu from "./MobileMenu";
import OffsetMenu from "./OffsetMenu";

const HeaderOne = () => {
    const [search, setSearch] = useState(true);
    const [offset, setOffset] = useState(true)
    const [mobileMenu, setMobileMenu] = useState(true);

    const handleSearch = () => {
        setSearch(!search);
    }

    const handleOffset = (e) => {
        e.preventDefault();
        setOffset(!offset);
    }

    const handleMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    }
    return (
        <>
            <OffsetMenu offset={offset} handleOffset={handleOffset} />
            <header className="header-wrap header-1">
                <div className="container-fluid d-flex justify-content-between align-items-center">
                    <div className="logo">
                        <Link to='/'>
                            <ReactLogo className="w-100" />
                        </Link>
                    </div>
                    <div className="header-right-area d-flex">
                        <div className="main-menu d-none d-xl-block">
                            <ul>
                                <li><Link to="/">HOME</Link></li>
                                <li><Link to="/about">about us</Link></li>
                                <li><Link to="/services">Services</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </div>
                        <div className="header-right-elements d-flex align-items-center justify-content-between">
                            <Link to="/contact" className="theme-btn d-none d-sm-block">Get Free Quote</Link>
                            <span onClick={handleOffset} className="side-menu-toggle d-none d-xl-block"><i className="fal fa-bars"></i></span>
                            <div className="d-inline-block ms-4 d-xl-none">
                                <div className="mobile-nav-wrap">
                                    <div id="hamburger" onClick={handleMobileMenu}>
                                        <i className="fal fa-bars"></i>
                                    </div>
                                    <MobileMenu mobileMenu={mobileMenu} handleMobileMenu={handleMobileMenu} />
                                </div>
                                <div className="overlay"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}

export default HeaderOne;